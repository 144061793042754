import type { ControllerStageDataManifest } from '@wix/platform-editor-sdk';
import type { Experiments } from '@wix/yoshi-flow-editor';

import {
  RICH_CONTENT_WIDGET_ID,
  CONNECT_TO_DATA_EV_ID,
  EDIT_CONTENT_EV_ID,
  NICKNAME,
  GFPP_HELP_ARTICLE_ID,
} from '../../consts';

// It's app manifest structure.
// The app manifest describes how the application and its components behave
// in the Editor after the Editor script creates the component on the stage.
// Read more about this here:
// https://bo.wix.com/wix-docs/fe-guild/editor-platform/platform-articles/learn/app-manifests/app-manifest
export const getWidgetStageData = (
  t: (key: string) => string,
  experiments: Experiments,
): ControllerStageDataManifest => ({
  [RICH_CONTENT_WIDGET_ID]: {
    default: {
      // Read more about GFPP manipulation:
      // https://bo.wix.com/wix-docs/fe-guild/editor-platform/platform-articles/learn/app-manifests/gfpp
      gfpp: {
        desktop: {
          mainAction1: {
            label: t('app.gfpp.connectToData'),
            actionId: CONNECT_TO_DATA_EV_ID,
          },
          ...(experiments.enabled('specs.wixRicos.enableOOISettingsGFPPByDefault')
            ? {
                mainAction2: 'DEFAULT1',
              }
            : {}),
          iconButtons: {},
          helpId: GFPP_HELP_ARTICLE_ID,
        },
        mobile: {
          iconButtons: {},
          helpId: GFPP_HELP_ARTICLE_ID,
        },
      },
      displayName: t('app.name'),
      nickname: NICKNAME,
    },
    connected: {
      gfpp: {
        desktop: {
          mainAction1: {
            label: t('app.gfpp.editContent'),
            actionId: EDIT_CONTENT_EV_ID,
          },
          mainAction2: 'DEFAULT1',
          iconButtons: {},
          helpId: GFPP_HELP_ARTICLE_ID,
        },
        mobile: {
          iconButtons: {},
          helpId: GFPP_HELP_ARTICLE_ID,
        },
      },
      displayName: t('app.name'),
      nickname: NICKNAME,
    },
  },
});
